.SocialFooterIcon {
  /* height: 50px !important; */
  font-size: 25px !important;
  margin-left: 10px;
  color: #23aef4;
  cursor: pointer;
}

.SocialFooterIcon:hover {
  color: #595c61;
}
